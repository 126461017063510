import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import PortfolioModal from "../components/portfolio/modal"
import PortfolioCarousel from "../components/portfolio/carousel"
import "./style.scss"
import bg from "./images/bg-img.png"
import quote from "./images/quote-left.svg"
import parshav from "./images/testimonials/parshav.png"
import piyush from "./images/testimonials/piyush.png"
import linkedin from "./images/linkedin.png"
import user from "./images/avatar.png"

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
    this.state = {
      modalShow: false,
      modalCurrent: 0
    }
    this.handlePortfolioClick = this.handlePortfolioClick.bind(this);
    this.setModal = this.setModal.bind(this);
  }
  componentDidMount(){
    heap.identify('unique_identifier');
  }

  handlePortfolioClick(index, e) {
    e.preventDefault();
    this.setModal(true, index);
  }

  setModal(isShown, current) {
    this.setState({
      modalShow: isShown,
      modalCurrent: current
    });
  }

  render() {
    return (
      <Layout>
         <script dangerouslySetInnerHTML={{ __html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("234486144");` }} />
        <SEO title="Spartan Labs"/>
        <section className="page-section bg-primary" id="about">
          <div className="container">
          <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="text-white mt-0">We've got what you need!</h2>
                <hr className="divider light my-4" />
                <p className="text-white-50 mb-4">
                  We are an early-stage startup based out of Bangalore, India.
                  We do everything related to web and mobile app development and
                  are extremely passionate about building world-class products.
                  We partner with businesses looking to harness the value of
                  digital, helping them to discover, design, develop and scale
                  exceptional digital experiences that deliver measurable
                  results.
                </p>
                <a
                  className="btn btn-light btn-xl js-scroll-trigger"
                  href="#services"
                  onClick={Scroller.handleAnchorScroll}
                >
                  Get Started!
                </a>
              </div>
            </div>
          </div>    
        </section>

        <section className="page-section" id="services">
          <div className="container">
            <h2 className="text-center mt-0 white-text">At Your Service</h2>
            <hr className="divider my-4" />
            <div className="row">
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-globe text-primary mb-4"></i>
                  <h3 className="h4 mb-2 white-text">Business Analysis</h3>
                  <p className="text-muted mb-0 text-white-50">
                    We analyse your business idea and our team of analysts are
                    experts at getting under the skin of the requirements by
                    thorough analysis and evaluation. We propose a solution and
                    design with minimal time and cost effort and maximum
                    efficiency and scalability.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-gem text-primary mb-4"></i>
                  <h3 className="h4 mb-2 white-text">Product Strategy</h3>
                  <p className="text-muted mb-0 text-white-50">
                    We plan & strategize the execution of your idea with expert
                    technical leads and a team of developers with a hint of
                    creativity and innovation! Also, we do make sure a quality
                    product in delivered within the stipulated time and cost -
                    your time and money is valuable.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-heart text-primary mb-4"></i>
                  <h3 className="h4 mb-2 white-text">Design</h3>
                  <p className="text-muted mb-0 text-white-50">
                    We identify problems and needs to produce opportunities for
                    helping product growth exponentially, defining product goal
                    shaping the better user experience.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-laptop-code text-primary mb-4"></i>
                  <h3 className="h4 mb-2 white-text">Development</h3>
                  <p className="text-muted mb-0 text-white-50">
                    Our skilled tech team employs best practices in order to
                    contribute highly to all existing projects to deliver large,
                    long-running products. We go for super-planned sprint based
                    delivery schedules and execute it with the best coding
                    standards.
                  </p>
                </div>
              </div>
              {/*  <div className="col-lg-3 col-md-6 text-center">
                <div className="mt-5">
                  <i className="fas fa-4x fa-heart text-primary mb-4"></i>
                  <h3 className="h4 mb-2">Marketing / Communications</h3>
                  <p className="text-muted mb-0">
                    We help our partners understand their product needs and set
                    a plan to widen the audience and capture data insights
                  </p>
                </div>
    </div> */}
            </div>
          </div>
        </section>
        <section className="page-section  bg-primary" id="team">
          <div className="container text-center">
            <h2 className="mb-4  white-text">Our Team</h2>
            <p className="text-muted mb-5  text-white-50">
              We are a team of dreamers and doers passionate about coding and
              want to build, create and innovate new technologies. We have all
              our members coming from different industry backgrounds and
              expertise with unmatchable knowledge of tech stacks like React,
              React native, Flutter, Node, Laravel etc.
            </p>
          </div>
        </section>
        {/*<section id="portfolio">
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-4 col-sm-6">
                <a
                  className="portfolio-box"
                  href="img/portfolio/fullsize/1.jpg"
                  onClick={this.handlePortfolioClick.bind(this, 0)}
                >
                  <Img
                    fluid={
                      this.props.data.images.edges[0].node.childImageSharp.fluid
                    }
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Category
                    </div>
                    <div className="project-name">Project Name</div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a
                  className="portfolio-box"
                  href="img/portfolio/fullsize/2.jpg"
                  onClick={this.handlePortfolioClick.bind(this, 1)}
                >
                  <Img
                    fluid={
                      this.props.data.images.edges[1].node.childImageSharp.fluid
                    }
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Category
                    </div>
                    <div className="project-name">Project Name</div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a
                  className="portfolio-box"
                  href="img/portfolio/fullsize/3.jpg"
                  onClick={this.handlePortfolioClick.bind(this, 2)}
                >
                  <Img
                    fluid={
                      this.props.data.images.edges[2].node.childImageSharp.fluid
                    }
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Category
                    </div>
                    <div className="project-name">Project Name</div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a
                  className="portfolio-box"
                  href="images/portfolio/fullsize/4.jpg"
                  onClick={this.handlePortfolioClick.bind(this, 3)}
                >
                  <Img
                    fluid={
                      this.props.data.images.edges[3].node.childImageSharp.fluid
                    }
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Category
                    </div>
                    <div className="project-name">Project Name</div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a
                  className="portfolio-box"
                  href="img/portfolio/fullsize/5.jpg"
                  onClick={this.handlePortfolioClick.bind(this, 4)}
                >
                  <Img
                    fluid={
                      this.props.data.images.edges[4].node.childImageSharp.fluid
                    }
                  />
                  <div className="portfolio-box-caption">
                    <div className="project-category text-white-50">
                      Category
                    </div>
                    <div className="project-name">Project Name</div>
                  </div>
                </a>
              </div>
              <div className="col-lg-4 col-sm-6">
                <a
                  className="portfolio-box"
                  href="img/portfolio/fullsize/6.jpg"
                  onClick={this.handlePortfolioClick.bind(this, 5)}
                >
                  <Img
                    fluid={
                      this.props.data.images.edges[5].node.childImageSharp.fluid
                    }
                  />
                  <div className="portfolio-box-caption p-3">
                    <div className="project-category text-white-50">
                      Category
                    </div>
                    <div className="project-name">Project Name</div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>*/}
        <section className="page-section" id="portfolio">
          <div class="container">
            <br />
            <div class="text-center  text-white">
              <h1>Testimonials</h1>
              <p class="text-muted">Read what our clients say about us!</p>
            </div>
            <br />
            <br />

            <div class="row">
              <div class="col-sm-4">
                <div class="testimonial-card" style={{ maxHeight: 400 }}>
                  <img src={bg} class="bg-img" alt="" />
                  <div class="testimonial-card_title-section">
                    <img src={parshav} alt="" />
                    <div>
                      <a href="https://www.linkedin.com/in/caparshavjain/">
                        <h4 class="testimonial-card_title">Parshav OM Jain</h4>
                      </a>
                      <p class="testimonial-card_designation">
                        Co-Founder, MGM
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      paddingRight: 20,
                    }}
                  >
                    <a href="https://www.linkedin.com/in/caparshavjain/">
                      <img
                        src={linkedin}
                        alt=""
                        style={{ width: 25, height: 25 }}
                      />
                    </a>
                  </div>
                  <div class="testimonial-card_comment">
                    The skilled team at Spartan Labs delivered a quality product
                    within a quick timeline. They’re hard-working while
                    displaying an optimistic attitude. Their communication was
                    seamless and professional. Customers can expect a talented
                    business as well as a development team that meets deadlines.
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="testimonial-card" style={{ maxHeight: 400 }}>
                  <img src={bg} class="bg-img" alt="" />
                  <div class="testimonial-card_title-section">
                    <img src={piyush} alt="" />
                    <div>
                      <a href="https://www.linkedin.com/in/piyush-rajawat-b5b73b1b/">
                        <h4 class="testimonial-card_title">Piyush Rajawat</h4>
                      </a>
                      <p class="testimonial-card_designation"></p>
                    </div>
                  </div>
                  <div
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      paddingRight: 20,
                    }}
                  >
                    <a href="https://www.linkedin.com/in/piyush-rajawat-b5b73b1b/">
                      <img
                        src={linkedin}
                        alt=""
                        style={{ width: 25, height: 25 }}
                      />
                    </a>
                  </div>
                  <div class="testimonial-card_comment">
                    Spartan Labs provides high-quality development services for
                    a reasonable rate. In addition to the caliber of their work,
                    their team is easy to work with, highly adaptable,
                    responsive, and accessible. They continue to be highly
                    valued partners.
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="testimonial-card" style={{ maxHeight: 400 }}>
                  <img src={bg} class="bg-img" alt="" />
                  <div class="testimonial-card_title-section">
                    <img src={user} alt="" />
                    <div>
                      <a href="https://slashweb.com.mx">
                        <h4 class="testimonial-card_title">Victor Lopez</h4>
                      </a>
                      <p class="testimonial-card_designation">
                        Co-Founder, SPLASHWEB
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      justifyContent: "flex-end",
                      display: "flex",
                      paddingRight: 20,
                    }}
                  >
                    <a href="https://slashweb.com.mx">
                      <span className="fas fa-2x fa-globe" style={{}} />
                    </a>
                  </div>
                  <div class="testimonial-card_comment">
                    Spartan Labs created a complete product ecosystem for a
                    ridesharing platform, providing full-stack development for
                    scalable web components, iOS, and Android apps, and a
                    backend system using Node and Express Js.
                  </div>
                </div>
              </div>
            </div>
            {/*<div class="row" style={{ marginTop: 35 }}>
              <div class="col-sm-4">
                <div class="testimonial-card">
                  <img src={bg} class="bg-img" alt="" />
                  <div class="testimonial-card_title-section">
                    <img src={user} alt="" />
                    <div>
                      <a href="https://www.linkedin.com/in/piyush-rajawat-b5b73b1b/">
                        <h4 class="testimonial-card_title">Sherwin Jupiter</h4>
                      </a>
                      <p class="testimonial-card_designation">
                        Founder, Paycab.io
                      </p>
                    </div>
                  </div>
                  <div class="testimonial-card_comment">
                    Spartan Labs managed full-stack development developed an
                    Uber-like application for iOS and Android. They are experts
                    in React and React Native. They have successfully launched
                    the application which has received positive feedback and
                    continues to see growing download numbers.
                  </div>
                </div>
              </div>
            </div>*/}
          </div>
        </section>
        <section
          className="page-section   bg-primary text-white"
          id="Technology"
        >
          <div className="container text-center">
            <h2 className="mb-4  white-text">Technology Capabilities</h2>
            <p className="text-muted mb-5  text-white-50">
              We are a web and mobile development company well versed with
              technologies like ReactJs, React Native, Flutter, Angular, NodeJS,
              ExpressJS,FeathersJS, Firebase, PHP, Laravel, GraphQL Java, etc.
            </p>
          </div>
        </section>

        <section className="page-section" id="contact">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0 white-text">Let's Get In Touch!</h2>
                <hr className="divider my-4" />
                <p className="text-muted mb-5 text-white-50">
                  Ready to start your next project with us? Send us an email or
                  fill up the form and we will get back to you as soon as
                  possible!
                </p>
              </div>
            </div>
            <div className="mt-0">
              <div className="mr-auto text-center">
                <i className="fas fa-envelope fa-3x mb-3 text-muted white-text"></i>
                <a
                  className="d-block white-text"
                  href="mailto:business@spartanlabs.in"
                >
                  business@spartanlabs.in
                </a>
              </div>
            </div>
            {/* <form method="post" action="https://spartanlabs-subscribe.herokuapp.com/subscription/form" class="listmonk-form">
            <div>
              <h3>Subscribe</h3>
              <p><input type="text" name="email" placeholder="E-mail" /></p>
              <p><input type="text" name="name" placeholder="Name (optional)" /></p>
            
              <p>
                <input id="77b96" type="checkbox" name="l" value="77b96554-05b0-4277-ade9-31a5cdd7ba42" />
                <label for="77b96">Opt-in list</label>
              </p>
              <p><input type="submit" value="Subscribe" /></p>
          </div>
        </form> */}
          </div>
        </section>
        <PortfolioModal
          show={this.state.modalShow}
          onHide={() => this.setModal(false, 0)}
        >
          <PortfolioCarousel
            images={this.props.data.images.edges}
            current={this.state.modalCurrent}
          />
        </PortfolioModal>
      </Layout>
    )
  }
}


export const imageData = graphql`
  query {
    images: allFile(filter: {relativePath: {glob: "portfolio/fullsize/*.jpg"}}, sort: {fields: name}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
